import { createRouter, createWebHistory } from 'vue-router';
import Overview from '../views/Overview/Overview.vue';
import TermAndService from '../views/TermAndService.vue';
import Privacy from '../views/Privacy.vue';
import Login from '../views/Login.vue';
// import BlankPage from '../views/BlankPage.vue';
import store from '../store';
import ErrorPage from '@/views/ErrorPage.vue';

const routes = [
  {
    path: '/error/:code',
    name: 'error',
    component: ErrorPage,
    meta: { auth: false },
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    alias: '/callback',
    meta: { layout: 'BlankLayout', auth: false },
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: () => import('../views/ForgotPassword.vue'),
    meta: { layout: 'BlankLayout', auth: false },
  },
  {
    path: '/privacy-policy',
    name: 'privacy-policy',
    component: Privacy,
    meta: { layout: 'BlankLayout', auth: true },
  },

  {
    path: '/terms-and-service',
    name: 'terms-and-service',
    component: TermAndService,
    meta: { layout: 'BlankLayout', auth: true },
  },

  {
    path: '/reset-password',
    name: 'reset-password',
    component: () => import('../views/ResetPassword.vue'),
    meta: { layout: 'BlankLayout', auth: false },
  },
  {
    path: '/',
    name: 'overview',
    component: Overview,
    meta: { layout: 'NavLayout', auth: true, showFilterPreset: true },
  },
  {
    path: '/comparison',
    name: 'comparison',
    component: () =>
      import(
        /* webpackChunkName: "comparison" */ '../views/Comparison/Comparison.vue'
      ),
    meta: { layout: 'NavLayout', auth: true },
  },
  {
    path: '/live-feed',
    name: 'live-feed',
    component: () =>
      import(/* webpackChunkName: "feed" */ '../views/LiveFeed.vue'),
    meta: {
      layout: 'NavLayout',
      auth: true,
      enableScrollTop: true,
      showFilterPreset: true,
    },
  },
  {
    path: '/explore',
    name: 'explore',
    component: () =>
      import(/* webpackChunkName: "explore" */ '../views/Explore/Explore.vue'),
    meta: {
      layout: 'NavLayout',
      auth: true,
      enableScrollTop: true,
      showFilterPreset: true,
    },
  },
  {
    path: '/location',
    name: 'location',
    component: () =>
      import(
        /* webpackChunkName: "location" */ '../views/Location/Location.vue'
      ),
    meta: { layout: 'NavLayout', auth: true, showFilterPreset: true },
  },
  // {
  //   path: '/influencer',
  //   name: 'influencer',
  //   component: () => import(/* webpackChunkName: "influencer" */ '../views/Influencer.vue'),
  //   meta: { auth: true, enableScrollTop: true },
  // },
  {
    path: '/influencer',
    name: 'influencer',
    component: () =>
      import(/* webpackChunkName: "influencer" */ '../views/Influencer2.vue'),
    meta: {
      layout: 'NavLayout',
      auth: true,
      enableScrollTop: true,
      showFilterPreset: true,
    },
  },
  {
    path: '/export',
    name: 'export',
    component: () =>
      import(/* webpackChunkName: "export" */ '../views/Settings/Export.vue'),
    meta: { layout: 'NavLayout', auth: true, hideSearch: true },
  },
  {
    path: '/profile',
    name: 'profile',
    component: () =>
      import(/* webpackChunkName: "profile" */ '../views/Profile/Profile.vue'),
    meta: { layout: 'NavLayout', auth: true, hideSearch: true },
  },
  {
    path: '/settings',
    name: 'setting-dashboard',
    component: () =>
      import(
        /* webpackChunkName: "setting" */ '../views/Settings/SettingDashboard.vue'
      ),
    meta: {
      layout: 'NavLayout',
      auth: true,
      adminOnly: true,
      hideSearch: true,
    },
  },
  {
    path: '/keyword-setting',
    name: 'keyword-setting',
    component: () =>
      import(
        /* webpackChunkName: "keywordsetting" */ '../views/Settings/KeywordSetting.vue'
      ),
    meta: {
      layout: 'NavLayout',
      auth: true,
      adminOnly: true,
      hideSearch: true,
    },
  },
  {
    path: '/category-setting',
    name: 'category-setting',
    component: () =>
      import(
        /* webpackChunkName: "categorysetting" */ '../views/Settings/CategorySetting/CategorySetting.vue'
      ),
    meta: {
      layout: 'NavLayout',
      auth: true,
      adminOnly: true,
      hideSearch: true,
    },
  },
  {
    path: '/source-setting',
    name: 'source-setting',
    component: () =>
      import(
        /* webpackChunkName: "customsource" */ '../views/Settings/CustomSource/CustomSource.vue'
      ),
    meta: {
      layout: 'NavLayout',
      auth: true,
      adminOnly: true,
      hideSearch: true,
    },
  },
  {
    path: '/user-manager',
    name: 'user-manager',
    component: () =>
      import(
        /* webpackChunkName: "user-manager" */ '../views/User/UserManager.vue'
      ),
    meta: { layout: 'NavLayout', auth: true, hideSearch: true },
  },
  {
    path: '/user-manager/new',
    name: 'user-add',
    component: () =>
      import(/* webpackChunkName: "user-add" */ '../views/User/AddUser.vue'),
    meta: { layout: 'NavLayout', auth: true, hideSearch: true },
  },
  {
    path: '/user-manager/edit',
    name: 'user-edit',
    component: () =>
      import(/* webpackChunkName: "user-edit" */ '../views/User/EditUser.vue'),
    meta: { layout: 'NavLayout', auth: true, hideSearch: true },
  },
  {
    path: '/group-list',
    name: 'group-list',
    component: () =>
      import(
        /* webpackChunkName: "group-list" */ '../views/Settings/SocialMediaAccountGroup/GroupList.vue'
      ),
    meta: { layout: 'NavLayout', auth: true, hideSearch: true },
  },
  {
    // UserProfile will be rendered inside User's <router-view>
    // when /user/:id/profile is matched
    path: '/campaign/:id',
    name: 'campaign',
    meta: { layout: 'NavLayout', auth: true },
    component: () =>
      import(
        /* webpackChunkName: "campaign" */ '../views/Campaign/Campaign.vue'
      ),
  },
  {
    // UserProfile will be rendered inside User's <router-view>
    // when /user/:id/profile is matched
    path: '/custom-campaign/:id',
    name: 'custom-campaign',
    meta: { layout: 'NavLayout', auth: true },
    component: () =>
      import(
        /* webpackChunkName: "campaign" */ '../views/Campaign/Campaign.vue'
      ),
  },
  {
    // UserProfile will be rendered inside User's <router-view>
    // when /user/:id/profile is matched
    path: '/campaign/comparison',
    name: 'campaign comparison',
    meta: { layout: 'NavLayout', auth: true },
    component: () =>
      import(
        /* webpackChunkName: "campaign comparison" */ '../views/Campaign/Comparison/CampaignComparison.vue'
      ),
  },
  {
    // UserProfile will be rendered inside User's <router-view>
    // when /user/:id/profile is matched
    path: '/campaign/add',
    name: 'add campaign',
    meta: { layout: 'NavLayout', auth: true },
    component: () =>
      import(
        /* webpackChunkName: "add campaign" */ '../views/Campaign/AddCampaign.vue'
      ),
  },
  {
    // UserProfile will be rendered inside User's <router-view>
    // when /user/:id/profile is matched
    path: '/campaign/edit/:id',
    name: 'edit campaign',
    meta: { layout: 'NavLayout', auth: true },
    component: () =>
      import(
        /* webpackChunkName: "edit campaign" */ '../views/Campaign/EditCampaign.vue'
      ),
  },
  {
    path: '/preset-management',
    name: 'preset-management',
    meta: { layout: 'NavLayout', auth: true },
    component: () =>
      import(
        /* webpackChunkName: "preset-management" */ '../views/Preset/PresetManagement.vue'
      ),
  },
  {
    path: '/share-preset/:id',
    name: 'share-preset',
    meta: { auth: false },
    component: () =>
      import(
        /* webpackChunkName: "preset-management" */ '../views/Preset/SharePreset.vue'
      ),
  },
  {
    path: '/welcome',
    name: 'welcome',
    meta: { layout: 'NavLayout', auth: true },
    component: () =>
      import(
        /* webpackChunkName: "welcome" */ '../views/ViewerNoDashboard.vue'
      ),
  },
  {
    path: '/prepare-viewer',
    name: 'prepare-viewer',
    meta: { layout: 'NavLayout', auth: true },
    component: () =>
      import(
        /* webpackChunkName: "prepare-viewer" */ '../views/prepareViewer.vue'
      ),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  linkExactActiveClass: 'active',
  routes,
});

router.beforeEach((to, from, next) => {
  // Virtual logout route -> would be redirect to login anyway - no need to interrupt
  // console.log(to.path, to.redirectedFrom);
  console.log('TO', to.path, to.query);
  if (to.path === '/login' && to.query && (to.query.expired || to.query.out)) {
    // console.log('Hayyy', to.query);
    store.dispatch('account/logout').then(() => {
      next();
    });
  } else if (to.meta.auth) {
    // Do simple check with cookie
    store
      .dispatch('account/getUsageStats')
      .then(() => {})
      .catch(() => {});
    if (store.getters['account/hasUserInfo']) {
      store.dispatch('seemore/close');
      store.dispatch('filter/initFilter').then(() => {
        const userRole = store.getters['account/userRole'];
        if (userRole === 'feedonly') {
          if (to.path === '/explore' || to.path === '/live-feed') {
            next();
          } else {
            next({ name: 'live-feed' });
          }
        } else if (to.meta.adminOnly) {
          if (userRole === 'admin' || userRole === 'domadmin') {
            next();
          } else {
            next(false);
          }
        } else if (userRole === 'accountmanager') {
          if (
            to.path === '/user-manager' ||
            to.path === '/user-manager/new' ||
            to.path === '/user-manager/edit'
          ) {
            next();
          } else {
            next({ name: 'user-manager' });
          }
        } else {
          next();
        }
      });
    } else {
      console.log('UMM');
      next({ name: 'login' });
    }
  } else {
    console.log('UMMM@');
    next();
  }
});

export default router;
