
import { ref, computed, ComputedRef, onBeforeMount } from 'vue';
import { useStore } from 'vuex';
import Dropdown from 'primevue/dropdown';
import { StarFilled, SearchOutlined } from '@ant-design/icons-vue';
import { ConvertedPresetType } from '@/types/preset-management/PresetType';
import AddPreset from '@/views/Preset/AddPreset.vue';
import { useRouter } from 'vue-router';
import ConfirmModal from '../Modal/ConfirmModal.vue';
import { notification } from 'ant-design-vue';
import utils from '@/services/utils';

export default {
  name: 'FilterPresetSelect',

  components: {
    Dropdown,
    StarFilled,
    SearchOutlined,
    AddPreset,
    ConfirmModal,
  },

  setup() {
    interface PresetGroup {
      groupName: string;
      children: Array<ConvertedPresetType>;
    }
    interface SlotProps {
      option: any;
      index: number;
    }
    const newFilterValue = ref<ConvertedPresetType>();
    const router = useRouter();
    const store = useStore();
    const showAddPreset = ref(false);
    const addPresetKey = ref(0);
    const disabledSave = computed(() => {
      return !isDirty.value || !selectValue.value;
    });

    const isSelectItem = (slotProps: SlotProps) => {
      if (
        selectValue.value &&
        selectValue.value.preset &&
        selectValue.value.preset.presetName
      ) {
        return (
          slotProps.option.preset.presetName ==
          selectValue.value.preset.presetName
        );
      } else {
        return false;
      }
    };

    const disabledSaveNew = computed(() => {
      return (
        !store.getters['presetFilter/activePreset'] ||
        (store.getters['presetFilter/activePreset'] && !isDirty.value)
      );
    });
    const convertedPresets = computed<ConvertedPresetType[]>(
      () => store.getters['presetFilter/convertedPresets'],
    );
    const categoryTreeData = computed(
      () => store.getters['config/categoryTreeData'],
    );
    const sourceTreeData = computed(
      () => store.getters['account/sourceTreeData'],
    );
    const currentFilter = computed(
      () => store.getters['filter/filterCriteria'],
    );
    const filterPresetGroup = computed<PresetGroup[]>(() => {
      const presets = convertedPresets.value || [];

      const favoritePresets = presets.filter(
        (preset) => preset.preset.isFavorite,
      );
      const noFavoritePresets = presets.filter(
        (preset) => !preset.preset.isFavorite,
      );
      let result: PresetGroup[] = [];

      if (favoritePresets.length > 0) {
        result.push({
          groupName: 'Favorite Presets',
          children: favoritePresets,
        });
      }
      if (noFavoritePresets.length > 0) {
        result.push({
          groupName: 'All Presets',
          children: noFavoritePresets,
        });
      }
      return result;
    });

    const selectValue = computed<ConvertedPresetType>({
      get: () => store.getters['presetFilter/activePreset'],
      set: (value) => {
        // store.dispatch('filter/setIsInit', false);
        store.dispatch('presetFilter/setIsDirty', false);
        store.dispatch('presetFilter/setActivePreset', value);
        const filter = utils.prepareCriteria(
          selectValue.value.filter,
          sourceTreeData.value,
          categoryTreeData.value,
        );
        store.dispatch('filter/updateTempCriteria', filter);
        store.dispatch('filter/applyFilter', { presetDirty: false });
      },
    });

    const isFocusDropDown = computed(() => selectValue.value != undefined);

    const isDirty: ComputedRef<Boolean> = computed(
      () => store.getters['presetFilter/isDirty'],
    );
    const showConfirmSave = ref(false);
    function isFavoritePreset(slotProps: {
      option: ConvertedPresetType;
    }): boolean {
      const group = filterPresetGroup.value.find(function (group) {
        return group.children.includes(slotProps.option);
      });
      return group?.groupName === 'Favorite Presets';
    }

    function truncateText(text: string): string {
      if (isDirty.value) {
        return text.length > 8 ? text.substring(0, 8) + '...' : text;
      } else {
        return text.length > 17 ? text.substring(0, 17) + '...' : text;
      }
    }

    const openAddNew = () => {
      addPresetKey.value = new Date().valueOf();
      showAddPreset.value = true;
    };
    const openSaveAsNew = () => {
      addPresetKey.value = new Date().valueOf();

      newFilterValue.value = {
        id: '',
        campaign: {
          isEnableCampaign: false,
          isShareCampaign: false,
          shareUser: [],
        },
        filter: JSON.parse(JSON.stringify(currentFilter.value)),
        preset: {
          presetDescription: '',
          presetName: '',
          isFavorite: false,
        },
      };
      showAddPreset.value = true;
    };

    const confirmSave = () => {
      showConfirmSave.value = true;
    };

    const saveChange = async () => {
      let res = await store.dispatch('presetFilter/updatePreset', {
        id: selectValue.value.id,
        data: {
          criteria: JSON.stringify(currentFilter.value),
          presetDescription: selectValue.value.preset.presetDescription,
          presetName: selectValue.value.preset.presetName,
          isEnableCampaign: selectValue.value.campaign.isEnableCampaign,
          isShareCampaign: selectValue.value.campaign.isShareCampaign,
          shareUser: selectValue.value.campaign.shareUser,
        },
      });
      if (res) {
        notification.success({
          message: `${selectValue.value.preset.presetName} has been saved successfully.`,
        });

        await store.dispatch(
          'presetFilter/loadAndSelect',
          selectValue.value.id,
        );
        store.dispatch('presetFilter/setIsDirty', false);
      }
      showConfirmSave.value = false;
    };

    const onAction = ({
      key,
    }: {
      key: 'save' | 'save-as-new' | 'add-new' | 'manage-presets';
    }) => {
      if (key === 'add-new') {
        openAddNew();
      } else if (key === 'manage-presets') {
        router.push('/preset-management');
      } else if (key === 'save') {
        confirmSave();
      } else if (key === 'save-as-new') {
        openSaveAsNew();
      }
    };
    const onAdded = async (presetId: string) => {
      await store.dispatch('presetFilter/loadCampaigns');
      const item = await store.dispatch('presetFilter/loadAndSelect', presetId);
      store.dispatch('filter/updateTempCriteria', item.filter);
      store.dispatch('filter/applyFilter');
      store.dispatch('presetFilter/setIsDirty', false);
    };

    onBeforeMount(async () => {
      store.dispatch('presetFilter/setIsDirty', false);
      await store.dispatch('presetFilter/loadPresets');
    });

    return {
      selectValue,
      filterPresetGroup,
      isDirty,
      isFavoritePreset,
      truncateText,
      onAction,
      showAddPreset,
      addPresetKey,
      onAdded,
      showConfirmSave,
      saveChange,
      currentFilter,
      disabledSave,
      disabledSaveNew,
      newFilterValue,
      isFocusDropDown,
      isSelectItem,
    };
  },
};
