import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "action-button-modal" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CampaignShareUser = _resolveComponent("CampaignShareUser")!
  const _component_a_spin = _resolveComponent("a-spin")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createBlock(_component_a_modal, {
    okType: 'danger',
    title: "Share this campaign dashboard",
    destroyOnClose: true,
    onCancel: $setup.closeModal
  }, {
    footer: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_a_button, {
          type: "light",
          outlined: true,
          onClick: $setup.closeModal
        }, {
          default: _withCtx(() => [
            _createTextVNode(" Cancel ")
          ]),
          _: 1
        }, 8, ["onClick"]),
        _createVNode(_component_a_button, {
          type: "primary",
          onClick: $setup.shareCampaign
        }, {
          default: _withCtx(() => [
            _createTextVNode("Share")
          ]),
          _: 1
        }, 8, ["onClick"])
      ])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_a_spin, { spinning: $setup.isLoading }, {
        default: _withCtx(() => [
          _createVNode(_component_CampaignShareUser, {
            item: $setup.itemData.campaign,
            onDataChange: $setup.onChangeUser,
            users: $setup.users
          }, null, 8, ["item", "onDataChange", "users"])
        ]),
        _: 1
      }, 8, ["spinning"])
    ]),
    _: 1
  }, 8, ["onCancel"]))
}