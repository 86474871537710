
import { computed, onMounted, PropType, reactive, ref } from 'vue';
import { useStore } from 'vuex';
import { notification } from 'ant-design-vue';
import {
  ConvertedPresetType,
  PresetCampaignType,
  PresetInfoType,
} from '@/types/preset-management/PresetType';
import { AdvanceFilterType } from '@/types/AdvanceSearchFilterType';
import PresetDetail from './PresetDetail/PresetDetail.vue';
import moment from 'moment';

export default {
  name: 'CreateCampaignDashboardModal',
  components: { PresetDetail },
  emits: ['add', 'update:visible', 'close'],
  props: {
    item: { type: Object as PropType<ConvertedPresetType> },
    firstValidate: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const presetData = reactive<{
      preset: Partial<PresetInfoType>;
      filter: Partial<AdvanceFilterType>;
      campaign: PresetCampaignType;
    }>({
      preset: { presetDescription: '', presetName: '' },
      filter: {
        category: [],
        subCategory: [],
        source: [],
        ownMedia: [],
        messageType: [],
        sentiment: [],
        contentType: [],
        time: {
          sinceDate: moment()
            .subtract(1, 'months')
            .startOf('day')
            .toISOString(),
          untilDate: moment().endOf('day').toISOString(),
        },
        matchCategoryMode: 'any',
        keywordPhrase: '',
        excludeKeywordPhrase: '',
        username: [],
        excludeUsername: [],
        usernameMatchMode: 'BEGINS_WITH',

        containAllList: [],
        containAnyList: [],
        containNoneList: [],
      },
      campaign: {
        isEnableCampaign: false,
        isShareCampaign: false,
        shareUser: [],
      },
    });

    const store = useStore();
    const accountId = computed(() => store.getters['account/accountId']);
    const isFormError = ref(false);

    const onSave = async () => {
      if (isFormError.value) return;

      const res = await store
        .dispatch('presetFilter/addPreset', {
          account: accountId.value,
          criteria: JSON.stringify(presetData.filter),
          isEnableCampaign: true,
          isFavorite: presetData.preset.isFavorite,
          isShareCampaign: false,
          presetDescription: presetData.preset.presetDescription,
          presetName: presetData.preset.presetName,
          shareUser: [],
        })
        .catch(() => {
          notification.error({
            message: `Already have a preset ${truncateText(presetData.preset.presetName)}.`,
          });
        });
      if (res) {
        notification.success({
          message: `${truncateText(presetData.preset.presetName)} has been added successfully.`,
        });
        await store.dispatch('presetFilter/loadCampaigns');
        emit('add', res.message.id);
        emit('update:visible', false);
      }
    };
    const onCancel = () => {
      emit('update:visible', false);
      emit('close');
      // reset
    };

    function truncateText(text: string | undefined): string {
      if (text == undefined) {
        return '';
      } else {
        return text.length > 60 ? text.substring(0, 60) + '...' : text;
      }
    }

    const onDetailChange = (preset) => {
      console.log(preset);
      presetData.preset = preset;
      isFormError.value = !preset.isValid;
    };
    const onFilterChange = (filter) => {
      console.log(filter);
      presetData.filter = filter;
    };
    const onCampaignChange = (campaign) => {
      console.log(campaign);
      presetData.campaign = campaign;
    };

    onMounted(() => {
      if (props.item) {
        console.log('item-set', props.item.filter);
        presetData.campaign = props.item.campaign;
        presetData.filter = props.item.filter;
        presetData.preset = props.item.preset;
      }
    });

    return {
      onSave,
      onCancel,
      onDetailChange,
      onFilterChange,
      onCampaignChange,
      presetData,
      isFormError,
    };
  },
};
