
import { useStore } from 'vuex';
import { PresetCampaignType } from '@/types/preset-management/PresetType';
import { UserType } from '@/types/users/UserType';
import { computed, onBeforeMount, PropType, reactive } from 'vue';

interface UserSharePreset {
  label: string;
  value: string;
}

interface CampaignShareUser {
  isShareCampaign: boolean;
  shareUser: string[] | undefined;
}

export default {
  name: 'CampaignShareUser',

  props: {
    users: {
      type: Array as PropType<UserType[]>,
      default: () => [],
    },
    item: {
      type: Object as PropType<PresetCampaignType>,
      required: true,
    },
  },

  emits: ['dataChange'],

  setup(props, { emit }) {
    const store = useStore();

    const userList = computed(() => {
      const currentUserId = store.state.account.userInfo.id;

      const result: UserSharePreset[] = [];
      for (const user of props.users) {
        if (currentUserId != user.id) {
          result.push({
            label: user.username,
            value: user.id,
          });
        }
      }

      return result;
    });

    const data = reactive<CampaignShareUser>({
      isShareCampaign: false,
      shareUser: [],
    });

    const update = () => {
      console.log('udpate....');
      emit('dataChange', { ...data });
    };

    onBeforeMount(() => {
      if (props.item?.shareUser) {
        data.isShareCampaign = props.item.shareUser.length > 0;
        data.shareUser = props.item?.shareUser;
      }
    });

    return { data, userList, update };
  },
};
