import moment from 'moment';
import helper from '@/services/helper';
import api from '../../services/api';
import _ from 'lodash';

const storeTempFilterKey = 'stored-filter';

const defaultTempFilter = () => {
  return {
    rawCategory: {},
    rawSource: {},
    category: [],
    subCategory: [],
    source: [],
    sentiment: ['positive', 'neutral', 'negative'],
    matchCategoryMode: 'any',
    contentType: ['text', 'photo', 'video'],
    ownMedia: [
      'website',
      'blog',
      'facebookPage',
      'twitterAccount',
      'youtubeChannel',
    ],
    messageType: ['origin', 'comment'],
    keywordPhrase: '',
    time: {
      sinceDate: moment().subtract(1, 'months').startOf('day').toISOString(),
      untilDate: moment().endOf('day').toISOString(),
    },
    // granularity: 'daily',
  };
};

export default {
  namespaced: true,
  state: {
    isInit: false,
    filterCollapsed: true,
    useFilter: false,
    filterCriteria: {},
    tempCriteria: {},
    backupTempCriteria: {},
    campaign: {},
    activeSource: true,
  },
  getters: {
    filterOpen: (state) => state.filterOpen,
    filterCriteria: (state) => state.filterCriteria,
    tempCriteria: (state) => state.tempCriteria,
    backupTempCriteria: (state) => state.backupTempCriteria,
    campaign: (state) => state.campaign,
    searchInput: ({ filterCriteria }) => {
      if (filterCriteria.keywordPhrase) {
        return filterCriteria.keywordPhrase;
      }
      return '';
    },
  },
  actions: {
    setDefaultFilter({ commit }) {
      commit('setDefaultFilter', defaultTempFilter());
    },
    async initFilter({ commit, rootGetters, state, dispatch }, forceInit) {
      if (!state.isInit || forceInit) {
        // Check if token expired
        // const tokenResult = await api.tokenCheck().catch(() => {
        //   // api.onExpiredSession(e);
        // });
        // if (!tokenResult) {
        //   return false;
        // }

        // Check if had temp filter in localStorage
        // let time = {};
        // const rawStoreDate = localStorage.getItem('stored-date');
        // if (rawStoreDate) {
        //   const storedDate = JSON.parse(rawStoreDate);
        //   time = {
        //     sinceDate: moment(storedDate.sinceDate),
        //     untilDate: moment(storedDate.untilDate),
        //   };
        //   // localStorage.removeItem('stored-date');
        // } else {
        //   time = {
        //     sinceDate: moment().subtract(1, 'months').toISOString(),
        //     untildate: moment().toISOString(),
        //   };
        // }
        await dispatch('config/getAccountCategory', null, { root: true });
        await dispatch('config/getOwnMedia', null, { root: true });

        const rawStoredFilter = localStorage.getItem(storeTempFilterKey);
        if (rawStoredFilter) {
          const storedFilterObj = JSON.parse(rawStoredFilter);
          console.log('USING STORED FILTER', storedFilterObj.time);

          commit('setTempCriteria', storedFilterObj);
          commit('setBackupTempCriteria', storedFilterObj);
          commit('syncFilterCriteria', storedFilterObj);
          commit('setInit', true);
        } else {
          // Set default manually
          // TODO set more default here
          // src
          const newTemp = defaultTempFilter();
          const srcTree = rootGetters['account/sourceTreeData'];
          newTemp.rawSource = srcTree.defaultState;
          // category
          const catTree = rootGetters['config/categoryTreeData'];
          const defaultList = rootGetters['config/defaultCategoryVisibleMap'];
          newTemp.rawCategory = catTree.defaultState;
          const catResult = helper.convertRawCategory(
            catTree.defaultState,
            defaultList
          );
          newTemp.category = catResult.category;
          newTemp.subCategory = catResult.subCategory;
          // own media
          const defaultOwnMedia = rootGetters['config/defaultOwnMediaData'];
          newTemp.ownMedia = [...defaultOwnMedia];
          commit('setTempCriteria', newTemp);
          commit('setBackupTempCriteria', newTemp);
          commit('syncFilterCriteria', newTemp); //? should be apply?
          commit('setInit', true);
        }
      }
    },
    storeTempFilter({ state }) {
      const { tempCriteria } = state;
      const finalCriteria = localStorage.setItem(
        storeTempFilterKey,
        JSON.stringify(tempCriteria)
      );
      localStorage.setItem(storeTempFilterKey, JSON.stringify(finalCriteria));
    },
    async toggleFilter({ commit, state }, collapsedState) {
      if (!collapsedState) {
        if (state.useFilter) {
          // Nothing here
        } else {
          // Nothing here too
        }
      }
      commit('toggleFilter', collapsedState);
    },
    async toggleSource({ commit }, collapsedSource) {
      commit('toggleSource', collapsedSource);
    },
    async applyFilter({ commit, state }) {
      commit('setBackupTempCriteria', state.tempCriteria);
      commit('applyFilter', state.tempCriteria);
      // if (data) {
      //   dispatch('presetFilter/setIsDirty', data.presetDirty, { root: true });
      // }
    },
    async createQueryUrl({ state }) {
      const criteria = state.tempCriteria;
      await api.createUrlPath(criteria).then((response) => {
        if (response && response.shortId) {
          const data = response.shortId;
          const searchURL = new URL(window.location);
          searchURL.searchParams.set('shortId', data);

          window.history.pushState({}, '', searchURL);
        }
      });
    },
    // async updateTempCriteria({ commit, state }, data) {
    //   const newData = {
    //     ...state.tempCriteria,
    //     ...data,
    //   };
    //   localStorage.setItem(storeTempFilterKey, JSON.stringify(newData));
    //   // console.log(newData);
    //   commit('setTempCriteria', newData);
    //   await api.createUrlPath(newData).then((response) => {
    //     if (response && response.shortId) {
    //       const data = response.shortId;
    //       const searchURL = new URL(window.location);
    //       searchURL.searchParams.set('shortId', data);

    //       window.history.pushState({}, '', searchURL);
    //     }
    //   });
    // },
    updateTempCriteria({ commit, state }, data) {
      const newData = {
        ...state.tempCriteria,
        ...data,
      };
      localStorage.setItem(storeTempFilterKey, JSON.stringify(newData));
      // console.log(newData);
      commit('setTempCriteria', newData);
    },
    setBothCriteria({ commit, state }, data) {
      const newData = {
        ...state.tempCriteria,
        ...data,
      };
      localStorage.setItem(storeTempFilterKey, JSON.stringify(newData));
      // console.log(newData);
      commit('setTempCriteria', newData);
      // commit('syncFilterCriteria', newData);
    },
    resetFilter({ commit, dispatch }) {
      localStorage.removeItem(storeTempFilterKey);
      localStorage.removeItem('stored-sort');
      localStorage.removeItem('stored-show');
      localStorage.removeItem('stored-paging');
      localStorage.removeItem('stored-limit');
      localStorage.removeItem('stored-sort-influencer');
      localStorage.removeItem('stored-show-feed');
      // const searchURL = new URL(window.location);
      // if (searchURL.searchParams.get('shortId')) {
      //   searchURL.searchParams.delete('shortId');
      //   window.history.pushState({}, '', searchURL);
      // }
      dispatch('initFilter', true);
      commit('resetFilter');
    },
    clearFilter({ commit }) {
      localStorage.removeItem(storeTempFilterKey);
      localStorage.removeItem('stored-sort');
      localStorage.removeItem('stored-show');
      localStorage.removeItem('stored-paging');
      localStorage.removeItem('stored-limit');
      localStorage.removeItem('stored-sort-influencer');
      localStorage.removeItem('stored-show-feed');
      // const searchURL = new URL(window.location);
      // if (searchURL.searchParams.get('shortId')) {
      //   searchURL.searchParams.delete('shortId');
      //   window.history.pushState({}, '', searchURL);
      // }
      commit('resetFilter');
    },
    setInit({ commit }, data) {
      commit('setInit', data);
    },
    cancelFilter({ commit, state }) {
      commit('setTempCriteria', state.backupTempCriteria);
    },
    setCampaign({ commit, dispatch }, data) {
      commit('setCampaign', data);
      dispatch('initFilter', true);
    },
    clearCampaign({ commit, dispatch }) {
      commit('resetCampaign');
      dispatch('initFilter', true);
    },
  },
  mutations: {
    toggleFilter(state, data) {
      state.filterCollapsed = data;
    },
    toggleSource(state, data) {
      state.activeSource = data;
    },
    applyFilter(state, data) {
      const f = JSON.parse(JSON.stringify(data));
      // Remove raw...
      // delete f.rawCategory;
      // delete f.rawSource;
      state.filterCriteria = f;
      state.useFilter = true;
      console.log('APPLY FIL', state);
    },
    syncFilterCriteria(state, data) {
      const f = JSON.parse(JSON.stringify(data));
      // Remove raw...
      delete f.rawCategory;
      delete f.rawSource;
      state.filterCriteria = f;
    },
    setTempCriteria(state, data) {
      // state.tempCriteria = { ...data };
      state.tempCriteria = _.cloneDeep(data);
    },
    setBackupTempCriteria(state, data) {
      const copiedData = JSON.parse(JSON.stringify(data));
      state.backupTempCriteria = copiedData;
    },
    resetTempCriteria(state) {
      state.tempCriteria = {};
    },
    resetFilter(state) {
      state.useFilter = false;
    },
    setInit(state, data) {
      state.isInit = data;
    },
    setDefaultFilter(state, data) {
      state.filterCriteria = data;
      state.isInit = false;
      state.useFilter = false;
    },
    setCampaign(state, data) {
      state.campaign = data;
    },
    resetCampaign(state) {
      state.campaign = '';
    },
  },
};
