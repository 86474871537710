import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ExportTreeSelect = _resolveComponent("ExportTreeSelect")!

  return (_openBlock(), _createBlock(_component_ExportTreeSelect, {
    "show-icon": true,
    name: "source",
    "initially-check-all": true,
    "tree-data": $setup.sourceTreeDataComputed,
    selected: $setup.sourceTreeSelected,
    onOnChange: $setup.onSourceSelect,
    "must-apply": true,
    "show-search": true
  }, null, 8, ["tree-data", "selected", "onOnChange"]))
}